.LayoutButtonComponent {
  font-size: 12px;
  display: flex;

  .button {
    display: flex;
    align-items: center;
    border: none;
    font-size: 14px;
    border-radius: 5px;

    padding: 8px 10px 8px 10px;
    flex: 1;
    z-index: 1;
    position: relative;
    cursor: pointer;

    .background {
      display: none;
    }

    &:hover {
      background: rgba(237, 237, 237, 0.461);
      color: #000;

      .icon {
        opacity: 1;
      }
    }

    &.active {
      background: rgba(237, 237, 237, 0.461);
      color: #000;
      font-weight: bold;

      .icon {
        opacity: 1;
      }

      .backgroundx {
        position: absolute;
        left: 50%;
        z-index: -1;
        top: 50%;
        content: '';
        width: 120%;
        height: 100%;
        display: block;
        pointer-events: none;
        transform: translateY(-50%) translateX(-2%);
        height: 100%;
        filter: brightness(0);
        /* our sprite is composed of 25 frames */
        width: 2500%;
        opacity: 1;
        animation: cdSprite 1s steps(24);
        animation-fill-mode: forwards;
        background-position: center;
        background-size: 100% 250%;
      }
    }

    .icon {
      font-size: 21px;
      padding-right: 14px;
      opacity: 0.3;
      width: 25px;
      text-align: center;
    }

    .bubble {
      margin-left: auto;
      background-color: rgba(0, 0, 0, 0.052);
      padding: 5px 10px;
      border-radius: 4px;
      font-size: 11px;
    }

    .development {
      position: absolute;
      right: -6px;
      top: -6px;
      background-color: rgba(0, 0, 0, 0.052);
      border-radius: 24px;
      height: 12px;
      width: 12px;
      background: rgb(39, 188, 96);
      line-height: 24px;
      text-align: center;
      font-size: 35px;
      font-weight: bold;
      color: rgb(72, 84, 163);
    }
  }
}

@keyframes cdSprite {
  0% {
    transform: translateY(-50%) translateX(-2%);
  }

  100% {
    transform: translateY(-50%) translateX(-98%);
    background-color: #000;
  }
}