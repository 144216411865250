.CalendarWeekBody {
    display: flex;
    font-size: 0;
    white-space: nowrap;
    flex: 1;
    width: 100%;
    flex-direction: column;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    flex: 1 1 auto;
    background-color: #fff;

    .scroll {
        flex: 1;
        font-size: 0;
        white-space: nowrap;
        z-index: 10;
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100%;
        max-width: 100%;
        max-width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: scroll;
        display: block;
        vertical-align: top;
        flex: 1 1 auto;
        position: relative;

        >.day {
            display: inline-block;
            width: 250px;
            font-size: 12px;
            box-sizing: border-box;
            border-right: 1px solid #ddd;
            position: relative;
            vertical-align: top;
            font-size: 0;

            &:last-child {
                border-right: none;
            }

            >.head {
                padding: 20px;
                position: sticky;
                top: 0px;

            }

            >.hour {
                height: 100px;
                min-height: 100px;
                max-height: 100px;
                position: absolute;
                left: 0;
                box-sizing: border-box;
                right: 0;
                vertical-align: top;
                border-bottom: 1px solid #ddd;
                transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
                display: flex;
                color: #000;
                cursor: pointer;

                i {
                    opacity: 0;
                    height: 40px;
                    width: 40px;
                    border-radius: 100px;
                    font-size: 20px;
                    display: flex;
                    margin: 5px;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    color: #000;
                    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                    left: 50%;
                    margin-left: -20px;
                    margin-top: -20px;
                    top: 50%;
                    ;

                    &:hover {
                        transform: scale(1.1);
                        background: rgba(255, 255, 255, 0.3);
                    }
                }

                b {
                    position: absolute;
                    padding: 0px 10px;
                    display: block;
                    opacity: 0.4;
                    box-sizing: border-box;
                    font-size: 11px;
                    font-weight: bold;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    top: 0;
                    left: 0;
                    transition: top 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

                    span {
                        opacity: 0.5;
                        font-weight: 100;
                        display: block;
                        letter-spacing: 0;
                        font-size: 10px;
                    }
                }

                &:hover {
                    background: #fff;
                    transform: scale(1.1);
                    border-radius: 0px 5px 5px 5px;
                    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
                    z-index: 102300;
                    z-index: 1000000;

                    i {
                        z-index: 1909;
                        opacity: 1;
                    }

                    b {
                        opacity: 1;
                        background-color: #333;
                        top: -38px;
                        border-radius: 5px 5px 0 0;
                        color: #fff;
                    }

                    &:after {
                        content: "";
                        display: block;
                        border: 4px solid #333;
                        left: 0;
                        top: 0;
                        bottom: -1px;
                        right: 0;
                        background-color: #fff;
                        position: absolute;
                        border-radius: 0px 5px 5px 5px;
                    }
                }

                &:first-child {

                    &:hover {
                        border-radius: 5px 5px 5px 0px;

                        b {
                            top: calc(100% + -2px);
                            border-radius: 0px 0px 5px 5px;

                        }

                        &:after {

                            border-radius: 5px 5px 5px 0px;
                        }
                    }
                }
            }
        }
    }




}