.CalendarHeaderComponent {
  gap: 10px;
  z-index: 100;
  padding: 0px;
  z-index: 10000;
  position: relative;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: -20px;
  left: 0;
  border-radius: 5px 5px 0 0;
  right: 0;
  z-index: 1000000000;
  background: #fff;

  .loader {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11231230;
    background: #fff;
  }

  // phone
  @media (max-width: 700px) {
    margin-bottom: 0;
    height: 100px;
    border-radius: 0;
  }

  .header {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    @media (max-width: 700px) {
      flex-wrap: wrap;
      min-height: 100px;
      height: 100px;
    }

    .title {
      margin-right: auto;
      position: relative;

      @media (max-width: 700px) {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #ddd;
        display: flex;
        align-items: center;
      }

      .current {
        font-size: 20px;
        display: flex;
        padding: 0 20px;
        align-items: center;

        .week {
          margin-right: 10px;
          font-size: 24px;
          text-transform: capitalize;

          b {
            font-weight: 100;
            opacity: 0.5;
            position: absolute;
            margin-left: 10px;
          }
        }

        .day {
          margin-left: 5px;
          margin-right: 5px;
        }

        .year {
          font-weight: 100;
          opacity: 0.5;
          margin-right: 10px;
        }
      }

      .status {
        font-size: 16px;
        display: none;
        font-weight: normal;
      }
    }

    .views {
      gap: 20px;
      height: 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 15px;
      min-width: 150px;
      max-width: 150px;
      border-left: 1px solid #ddd;
      position: relative;

      @media (max-width: 700px) {
        gap: 0;
        margin-right: auto;
        min-width: 100px
      }

      &:hover {
        .item {
          display: block;
        }
      }

      .selection {
        text-transform: uppercase;
        padding: 0 20px;
        display: flex;
        position: relative;
        z-index: 10000;
        flex: 1;
        align-items: center;
        cursor: pointer;

        &:hover {
          color: #000;
          background-color: #eee;
        }

        i {
          margin-left: auto;
          padding-left: 10px;
        }
      }

      .options {
        position: absolute;
        top: 100%;
        left: -1px;
        margin-top: 0;
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.171);
        right: -1px;
        border: 1px solid #ddd;
        border-top: none;
        z-index: 1000;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        background: rgb(255, 255, 255);
      }

      .item {
        cursor: pointer;
        padding: 10px 20px;
        background: #fff;
        font-weight: normal;
        border-top: 1px solid #ddd;

        @media (max-width: 700px) {
          border-radius: 0px;
          font-size: 14px;
        }

        &.active {
          background: rgba(0, 0, 0, 0.1);
          display: block;
          font-weight: bold;
          color: #000;
        }

        &:hover {
          color: rgb(0, 0, 0);

          background: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .controls {
      display: flex;

      .button {
        height: 50px;
        cursor: pointer;
        width: 50px;
        border-left: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;

        &.text {
          font-size: 15px;
          width: auto;
          padding: 0 20px;
        }

        @media (max-width: 700px) {
          height: 50px;
          width: 50px;
        }

        @media (max-width: 300px) {
          width: 30px;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}