.ConstructorOne {
    .InterfaceConstructor {

        padding: 20px;
        display: flex;
        gap: 20px;
        flex-direction: column;

        .middlewares {
            background-color: rgb(255, 255, 255);
            display: flex;
            padding: 10px;
            gap: 10px;
        }

        .methods {
            background-color: rgb(255, 255, 255);
            display: flex;
            padding: 10px;
            gap: 10px;
        }
    }
}