.CalendarWeekHeader {
    display: block;
    font-size: 0;
    white-space: nowrap;
    height: 50px;
    z-index: 100;
    position: sticky;
    top: 0;
    overflow: hidden;
    padding-left: 59px;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    box-shadow: 0px 0px 71px rgba(0, 0, 0, 0.1);
    background: rgb(255, 255, 255);
    background-color: rgb(61, 61, 61);
    z-index: 1000000232323;
    box-sizing: border-box;

    >.column {
        display: inline-block;
        width: 250px;
        background: rgb(44, 44, 44);
        min-width: 250px;
        height: 50px;
        font-size: 14px;
        box-sizing: border-box;
        border-left: 1px solid rgba(132, 132, 132, 0.4);
        line-height: 50px;
        padding: 0 16px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        position: relative;
        padding-left: 30px;

        &.withoutColor {
            padding-left: 15px;
        }

        b {
            color: rgba(255, 255, 255, 0.475);
            margin-right: 5px;
            font-weight: 100;

        }

        span {
            font-weight: 100;
            font-size: 10px;
            padding: 0 32px 0 12px;
            background-color: rgb(61, 61, 61);
            border-radius: 100px;
            position: absolute;
            right: -20px;
            top: 50%;
            margin-top: -30px;
            display: flex;
            align-items: center;
            height: 60px;

            em {
                font-weight: bold;
                font-style: normal;
                margin-right: 5px;
            }
        }

        .color {
            height: 12px;
            width: 12px;
            border-radius: 5px;
            position: absolute;
            left: 10px;
            top: 50%;
            margin-top: -6px;
        }


    }
}