.Internet {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 30px;
  color: #fff;
  border-radius: 5px;
  background: rgba(25, 25, 25, 0.2);
  padding: 5px 10px;
  font-size: 13px;
  z-index: 1000;
  font-weight: 100;
}

.DishComponent {
  background: #202124;

  flex: 1;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 10000;
  max-width: 100vw;




  .body {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    bottom: 100px;
    right: 0;
    left: 0;
  }

  ol {
    padding: 0 25px;
    margin: 0px 0 0 0;
    // linear background red to black vertical
    flex: 1;
    overflow-y: scroll;

    li {
      display: flex;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 3px;
      margin-bottom: 5px;
      color: #fff;
      font-size: 14px;
      padding: 5px;
      align-items: center;
      padding-left: 55px;
      position: relative;
      overflow: hidden;

      >i {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: rgb(0, 0, 0);
        color: rgba(255, 255, 255, 0.543);
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
      }

      &.ok {
        border-left: 3px solid green;
      }

      &.ko {
        border-left: 3px solid red;
      }


      b {
        margin-left: auto;
        background: rgba(255, 255, 255, 0.098);
        border-radius: 4px;
        padding: 5px 10px;
        font-weight: 100;
        font-size: 15px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .la-check {
          color: greenyellow;
        }

        .la-times {
          color: rgb(255, 47, 47);
        }
      }
    }
  }

  .closer {
    font-size: 24px;
    position: absolute;
    right: 20px;
    cursor: pointer;
    top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    opacity: 0.5;
    color: #000;
    z-index: 10000;

    &:hover {
      opacity: 1;
    }
  }

  .layout {
    flex: 1;
    position: absolute;
    left: 0;
    bottom: 0px;
    right: 0;
    top: 80px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: rgb(251, 251, 251);
  }



  .option {
    background: #fff;
    margin: 5px 30px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    overflow: hidden;
    align-items: center;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);

    span {
      max-width: 210px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      >b {
        padding: 5px 10px;
        display: inline-block;
        font-size: 11px;
        margin-left: 10px;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.063);
        border-radius: 100px;
      }
    }

    >i {
      font-size: 21px;
      height: 35px;
      width: 35px;
      margin: 5px 10px 5px 5px;
      line-height: 35px;
      text-align: center;
      background-color: rgb(236, 236, 236);
      color: rgb(68, 90, 215);
      border-radius: 10000px;
    }



    >b {
      margin-left: auto;
      background: rgb(255, 255, 255);
      padding: 5px 10px;
      border-radius: 3px;
      margin-right: 8px;
      box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.4);

      i {
        margin-right: 0;
      }

      .la-check {
        color: rgb(67, 165, 100);
      }

      &.selectable:hover {
        cursor: pointer;
        background: #333;
      }
    }
  }

  .box {
    padding: 20px 0;
    background-color: #ddd;
  }

  .title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    padding: 0px 20px 0px;
    background-color: #fff;
    justify-content: center;
    display: flex;
    flex-direction: column;
    z-index: 100;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);

    b {
      display: block;
      color: rgb(0, 0, 0);
      font-weight: bold;
    }
  }

  .divisor {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.484);
    padding: 15px 40px;
    font-weight: bold;
    background-color: rgb(242, 242, 242);
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.08);


    &::after {
      content: "";
      height: 200px;
      display: block;
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: -1;
      background-image: linear-gradient(to bottom, rgb(196, 196, 196), rgba(242, 242, 242, 0));
    }
  }


  .ScenaryComponent {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    background: #202124;


    .canvas {
      flex: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 0px;
      overflow: hidden;
      max-width: 100%;
      box-sizing: border-box;

      .cameras {
        flex: 1;
        gap: 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .scenary {
    display: flex;
    flex: 1;
    width: 100%;


    .container {
      display: flex;
      width: 100%;
      flex-direction: row;

      .dish-unique {
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;

        .dish-unique-cameras {
          overflow: scroll;
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          vertical-align: middle;
          flex: 1;
        }
      }

      .dish-center {
        display: flex;
        flex: 1;
        overflow: hidden;
        flex-direction: column;
      }

      .scene {
        display: flex;
        flex: 1;
        overflow: hidden;

        &.fullscreen {
          height: 200px;
          background: rgba(0, 0, 0, 0.2);
          max-height: 200px;
        }
      }

      .cameras {
        overflow: scroll;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        vertical-align: middle;
        flex: 1;
      }

      .fullscreen {
        .video button {
          display: none;
        }
      }

      .DishVideoComponent {
        background: #111;
        position: relative;
        vertical-align: middle;
        align-self: center;
        max-width: 100% !important;
        border-radius: 10px;
        overflow: hidden;
        display: inline-block;
        box-shadow: var(--shadow-dark);

        animation: show 0.4s ease;

        button {
          z-index: 1000;
          position: absolute;
          height: 50px;
          width: 50px;
          font-size: 22px;
          border-radius: 9px;
          cursor: pointer;
          border: none;
          background: rgba(0, 0, 0, 0.3);
          color: rgba(255, 255, 255, 0.722);

          &:hover {
            background: rgba(0, 0, 0, 0.5);
          }
        }

        .fullscreen {
          bottom: 10px;
          left: 10px;
          display: block !important;
        }

        .captureShot {
          bottom: 10px;
          left: 70px;
        }

        .coverSystem {
          bottom: 10px;
          left: 130px;
        }

        .panLeft {
          bottom: 70px;
          right: 70px;
        }

        .panRight {
          bottom: 10px;
          right: 70px;
        }

        .tilTop {
          bottom: 70px;
          right: 130px;
        }

        .tilBottom {
          bottom: 10px;
          right: 130px;
        }

        .zoomLess {
          bottom: 10px;
          right: 10px;
        }

        .zoomMore {
          bottom: 70px;
          right: 10px;
        }
      }



    }
  }


  .selector {
    font-size: 13px;
    margin: 20px 20px 0;

    .placeholder {
      padding: 13px 25px 13px 15px;
      border-radius: 5px;
      background-color: #fff;
      display: flex;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
      align-items: center;
      position: relative;

      span {
        display: inline;
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      i {
        &:first-child {
          font-size: 25px;
          opacity: 0.5;
          margin-right: 15px;
        }

        &:last-child {
          font-size: 20px;
          width: 40px;
          text-align: center;
          opacity: 0.2;
          position: absolute;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          right: 0;
        }
      }
    }

    .chooses {
      padding: 5px 0px 0px;
      background: rgba(0, 0, 0, 0.103);
      margin-top: -5px;
      border-radius: 0px 0px 5px 5px;
      overflow: hidden;

      .choose {
        padding: 10px 15px;
        font-size: 13px;
        display: block;
        border-bottom: 1px solid #eee;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &:hover {
          background: rgb(124, 124, 124);
        }
      }
    }

  }
}

// video {
//   position: absolute;
//   left: 0;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   vertical-align: middle;
//   align-self: center;
//   border-radius: 10px;
//   overflow: hidden;
//   display: inline-block;
//   box-shadow: var(--shadow-dark);
//   background: #fff;
//   animation: show 0.4s ease;
// }