.EndpointComponent {
  background-color: rgb(22, 28, 26);
  color: #fff;
  border-radius: 4px;
  padding: 5px 5px 5px 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  i {
    font-size: 20px;
    opacity: 0.4;
  }
  .endpoint {
    color: aqua;
  }
  .method {
    font-weight: 100;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 11px;
    color: rgb(188, 255, 255);

    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: auto;
  }
}
