.LayoutDivisorComponent {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.589);
  display: flex;
  margin-top: 10px;


  .text {
    padding: 20px 20px 5px 5px;
  }
}