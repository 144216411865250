.ProductsTicketComponent {

    .header {
        padding: 0.5rem 1rem;
        background-color: #000;
        color: #fff;

        @media print {
            display: none;
        }
    }

    .products {
        display: flex;
        padding: 0 1rem;
        flex-direction: column;

        .product {
            display: flex;
            border-bottom: 0.1px solid #ddd;
            flex: 1;
            padding: 0 1rem;

            .name {
                padding: 0.5rem 0;
                width: 15rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .price {
                margin-left: auto;
                width: 100px;
                text-align: right;
                font-weight: bold;
                padding: 0.5rem 0;
            }
        }
    }
}