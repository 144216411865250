.DishSectionsLayoutsComponent {


  .systems {
    font-size: 0;
    padding: 30px 20px 30px 30px;

    .infor {
      font-size: 14px;
      padding: 0 0 30px;
      position: relative;
      padding-left: 35px;

      i {
        font-size: 30px;
        position: absolute;
        left: -10px;
        top: 12px;
      }
    }

    .system {
      background: #202124;
      text-align: center;
      border-radius: 10px;
      color: #fff;
      width: 165px;
      height: 165px;
      margin-bottom: 10px;
      margin-right: 10px;
      display: inline-block;
      font-size: 12px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      z-index: 1;

      i {
        font-size: 40px;
        display: block;
        position: absolute;
        width: 85px;
        height: 85px;
        top: 50%;
        margin-top: -45px;
        margin-left: -45px;
        left: 50%;
        border-radius: 100px;
        background-color: #2d2e34;
        text-align: center;
        line-height: 85px;
        transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      &:hover {
        box-shadow: 0px 0px 0px 4px #3a5bfc;
        background-color: #0c0d0f;

        i {
          background-color: #2e3765;
          box-shadow: 0px 0px 0px 55px #2f3244a5;
          transform: scale(0.9);
        }


      }

      .multiple {
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;

        i:first-child {
          transform: scale(0.8) translateX(-25px) translateY(-15px);
          z-index: 1000;
          font-size: 35px;
        }

        i:last-child {
          transform: scale(0.8) translateX(25px) translateY(15px);
          opacity: 0.5;
          font-size: 30px;

        }
      }

      span {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        letter-spacing: 2px;
        font-weight: 100;
        opacity: 0.5;
        padding: 15px 0;
      }
    }
  }

  .selector {

    margin: 20px 20px 0;

    .placeholder {
      padding: 13px 25px 13px 15px;
      border-radius: 5px;
      background-color: #fff;
      display: flex;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);

      align-items: center;
      position: relative;

      i {
        &:first-child {
          font-size: 25px;
          opacity: 0.5;
          margin-right: 15px;
        }

        &:last-child {
          font-size: 20px;
          width: 40px;
          text-align: center;
          opacity: 0.2;
          position: absolute;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          right: 0;
        }
      }
    }

    .chooses {
      padding: 5px 0px 0px;
      background: #ddd;
      margin-top: -5px;
      border-radius: 0px 0px 5px 5px;

      .choose {
        padding: 15px;
        font-size: 14px;
        display: block;
        border-bottom: 1px solid #eee;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &:hover {
          background: rgb(124, 124, 124);
        }
      }
    }

  }

  .submit {
    padding: 15px 35px;
    margin-right: 20px;
    margin-left: auto;
    margin-top: 20px;
    color: #000;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    font-weight: normal;
    cursor: pointer;

    i {
      margin-left: 10px;
      font-size: 25px;
    }
  }


}