@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap');

.HeaderTicketComponent {

    padding: 1rem 0;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    border-radius: 10px;
    font-size: 1rem;

    .companyName {
        font-size: 1.6rem;
        font-weight: bold;
        margin-top: 1rem;
        padding-left: 1rem;
    }

    .companyEmail {
        font-weight: 100;
        opacity: 0.6;
        padding-left: 1rem;

    }

    .companyAddress {
        padding-left: 1rem;

    }

    .date {
        margin-top: 1rem;
        padding-left: 1rem;

    }

    .barcode {
        font-size: 2.4rem;
        padding: 1rem;
        border-bottom: 1px dashed #aaa;
        text-align: center;
        font-family: 'Libre Barcode 128 Text', cursive;

        // princ css
        @media print {
            font-size: 2.6rem;
            color: #000;
            border-bottom: none;
            text-align: left;
        }
    }


}