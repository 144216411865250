.LayoutFormSignatureComponent {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  z-index: 1;
  background-size: cover;
  border: 1px solid #ccc;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    bottom: 0;
    z-index: -1;
    opacity: 0.3;
  }

  .image {
    position: absolute;
    left: 0;
    top: 0;

  }

  .clean,
  .image i {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    border-radius: 20px;
    width: 40px;
    margin: 20px;
    height: 40px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 15px 25px,
      rgba(0, 0, 0, 0.02) 0px 5px 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  .clean:hover,
  .image i:hover {
    color: #fff;
    background-color: #000;
    cursor: pointer;
  }
}