.LayoutHeaderComponent {
  font-size: 12px;
  display: flex;
  border-radius: 5px 5px;
  overflow: hidden;
  background: #fff;
  color: rgba(0, 0, 0, 0.721);
  box-shadow: var(--shadow-component);
  flex-direction: column;
  border: 1px solid rgb(227, 227, 227);

  @media screen and (max-width: 768px) {
    border-left: none;
    border-right: none;
    border-radius: 0;
    border-bottom: none;
    box-shadow: none;
  }

  .bottom {
    background: rgb(152, 154, 158);
    padding: 10px 15px;
    color: #fff;
    display: flex;
    gap: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    align-items: center;

    .icon {
      font-size: 17px;
      opacity: 0.5;
    }
  }

  .content {
    display: flex;
    gap: 20px;

    padding: 10px 28px 10px 10px;
    align-items: center;

    // phone
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0px;
      gap: 0;
      align-items: flex-start;
    }

    .top {
      display: flex;
      gap: 20px;
      padding: 20px;
      align-items: center;

      @media (max-width: 768px) {
        gap: 10px;
      }
    }

    >.top .icon {
      font-size: 54px;
      opacity: 0.3;

      @media (max-width: 768px) {
        font-size: 35px;
      }
    }

    .text {
      gap: 2px;

      @media (max-width: 768px) {
        padding-left: 10px;

        .title {
          font-size: 20px;
        }

        .description {
          font-size: 14px;
        }
      }

      .title {
        font-size: 25px;
        font-weight: bold;
        padding: 0;
        line-height: 1;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      .description {
        padding: 0;
        font-size: 18px;
        font-weight: 400;
        opacity: 0.5;

        @media (max-width: 768px) {
          font-size: 15px;
        }
      }
    }

    .buttons {
      margin-left: auto;
      display: flex;
      gap: 10px;



      .button {
        border: none;
        font-size: 13px;
        display: flex;
        color: rgb(0, 0, 0);
        text-transform: uppercase;
        padding: 10px 20px 10px 15px;
        border-radius: 5px;
        background-color: #eee;
        align-items: center;
        gap: 15px;
        font-weight: bold;

        .icon {
          font-size: 20px;
          padding-right: 0;
        }
      }

      @media (max-width: 768px) {
        background: rgb(251, 251, 251);
        margin-left: 0;
        min-width: 100%;
        border-top: 1px solid rgb(227, 227, 227);

        .button {
          background-color: transparent;
        }
      }
    }
  }
}