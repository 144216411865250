.LayoutFormNumberComponent {
  position: relative;

  .have-icon {
    input {
      text-indent: 40px;
    }
  }

  input {
    text-indent: 9px;
    border: 1px solid #ccc;
    display: block;
    flex: 1;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
    background-color: transparent;

    &:focus {
      border-color: #000;
      outline: 5px solid rgba(0, 0, 0, 0.1);
    }
  }
}