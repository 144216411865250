.LegalDishComponent {
    margin: 20px;
    font-size: 14px;
    opacity: 0.4;
    line-height: 1.6;
    display: block;
    padding-left: 40px;
    position: relative;

    i {
        position: absolute;
        font-size: 30px;
        left: 0;
        top: 0;
    }
}