.SelfieDishComponent {

    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    z-index: 100000;

    span {
        border-radius: 20px;
        background: rgb(67, 94, 213);
        padding: 0px 10px;
        height: 30px;
        display: flex;
        align-items: center;
        color: #fff;
    }

    em {
        border-radius: 20px;
        background: #fff;
        padding: 5px 10px;
        font-style: normal;
        font-weight: bold;
    }
}