.LayoutFormCheckboxComponent {
  display: flex;
  align-items: center;
  gap: 10px;

  .box {
    border: 1px solid #ccc;
    display: block;
    flex: 1;
    height: 45px;
    padding: 0 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    font-size: 14px;
    background-color: transparent;
    display: flex;
    gap: 11px;
    align-items: center;
    transition: all 0.2s ease-in-out;

    .checkbox {
      height: 27px;
      width: 47px;
      line-height: 27px;
      text-align: center;
      border-radius: 4px;
      display: flex;
      box-sizing: border-box;
      padding: 3px;
      align-items: center;
      color: #000;
      background-color: #eee;
      transition: all 0.2s ease-in-out;

      i {
        height: 21px;
        width: 21px;
        background: rgba(99, 99, 99, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

        border-radius: 3px;
        color: #fff;
      }
    }

    &.checked .checkbox {
      color: #fff;

      i {
        background-color: #000;
        margin-left: 19px;
      }
    }

    &:hover {
      border-color: #000;
      outline: 5px solid rgba(0, 0, 0, 0.1);
    }
  }

  .label {
    font-weight: 500;
    font-size: 14px;
  }
}