.LayoutMapComponent {
  background: #333;
  border-radius: 5px;
  flex: 1;
  overflow: hidden;
  min-height: 250px;

  .marker {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;

    .dot {
      border-radius: 100px;
      height: 8px;
      width: 8px;
      background: #000;
    }
  }
}