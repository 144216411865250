.LayoutFormTitleComponent {
  padding: 0 0 10px 0;
  flex: 1;
  align-items: center;
  gap: 10px;
  position: relative;
  padding-right: min(15px, 1%);

  .title {
    font-size: 15px;
    font-weight: 600;
    list-style: 20px;
    height: 20px;
  }

  .top {
    display: flex;
  }

  .buttons {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;

    button {
      border: none;
      padding: 0;
      background: none;
      text-decoration: underline;
      opacity: 0.6;
    }
  }

  .description {
    font-size: 13px;
    color: rgb(0, 0, 0);
    opacity: 0.6;
    margin-top: -2px;
    font-weight: 100;
  }

  .LayoutButtonComponent .button {
    padding: 0;
    margin: 0;
    border: none;
    font-size: 14px;
    padding: 0;
    background: none;
    text-decoration: underline;
    opacity: 0.6;

    .icon {
      font-size: 20px;
      padding-right: 5px;
    }
  }
}