.LayoutCalendarYearComponent {
  display: flex;
  flex: 1;
  // slideLeft
  padding: 15px;
  background-color: #fff;

  .year {
    display: flex;
    flex: 1;
  }

  .frame {
    display: flex;
    flex: 1;
  }

  .months {
    flex: 1;
    gap: 10px;
    flex-wrap: wrap;
    display: grid;
    grid-gap: 15px;

    grid-template-columns: 1fr 1fr 1fr;
    // min 1200px screen
    align-content: flex-start;

    @media (max-width: 2500px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (max-width: 2000px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 1500px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-gap: 0;
      gap: 0;
    }

    flex: 1;

    .month {
      width: 100%;
      background: #fff;
      border-radius: 5px 5px;
      position: relative;
      background: #fff;
      border: 1px solid rgb(217, 217, 217);
      border-radius: 10px;
      overflow: hidden;
      box-sizing: border-box;

      @media (max-width: 700px) {
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        border-top: none;
      }

      .name {
        font-weight: bold;
        font-size: 20px;
        text-transform: capitalize;
        padding: 15px 20px;
        border-bottom: 1px solid #eee;
      }

      .days {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        font-size: 10px;
        margin-right: -1px;
        text-transform: uppercase;
        margin-bottom: -1px;

        .day {
          border-bottom: 1px solid #eee;
          border-right: 1px solid #eee;
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          box-sizing: border-box;

          &.active:hover {
            background: rgb(223, 223, 223);
            cursor: pointer;
          }

          &.th {
            font-size: 10px;
            padding: 8px 0;
            background: rgb(52, 52, 52);
            border-right: rgb(52, 52, 52);
            aspect-ratio: 0;
            color: #fff;
          }

          &:nth-child(7n + 14) {
            background: rgb(247, 247, 247);
          }

          &:nth-child(7n + 8) {
            background: rgb(247, 247, 247);
          }

          &.today {

            scroll-margin-top: 150px;

            .number {
              background: rgb(51, 73, 184);
              color: #fff;
              height: 45px;
              width: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              border-radius: 30px;

            }
          }
        }
      }
    }
  }
}