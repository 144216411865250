.LayoutFormInformationComponent {
  padding: 0 0 10px 0;
  flex: 1;
  align-items: center;
  gap: 10px;
  position: relative;
  padding-right: min(15px, 1%);
  border: 1px solid #ddd;
  padding: 5%;
  display: flex;
  border-radius: 5px;

  >i {
    font-size: 50px;
    opacity: 0.2;
    margin-right: 20px;
  }
}