.SearchComponent {
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  cursor: pointer;

  .lent {
    height: 40px;
    width: 40px;
    font-size: 20px;
    font-weight: bold;
    z-index: 1000;
    color: var(--primary-color);
    pointer-events: none;
    left: 30px;
    display: flex;
    align-items: center;
    position: absolute;
  }

  input {
    margin-left: auto;
    height: 40px;
    background: rgba(0, 0, 0, 0.43);
    color: rgba(255, 255, 255, 0.533);

    border: none;
    padding: 0 70px;
    box-sizing: border-box;
    flex: 1;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: none;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.3);
      outline: 1px solid var(--primary-color);
      box-shadow: 0px 5px 50px 5px var(--input-shadow);
    }

    // phone
    @media (max-width: 600px) {
      border-radius: 0 !important;
      border-bottom: 1px solid #ddd;
    }
  }

  .bar {
    background: #000;
    height: 5px;
    opacity: 0.4;
    position: absolute;
    bottom: 100%;
    margin-bottom: 1px;
    left: 0;
    // transition bounce
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .wave {
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    height: 40px;
    pointer-events: none;
    top: -23px;
    opacity: 0.2;
  }
}

.LayoutFormSelectComponent .SearchComponent {
  .lent {
    left: 12px;
    height: 35px;
  }

  input {
    height: 45px;
    font-size: 14px;
    padding-left: 47px;
    color: #000;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
}