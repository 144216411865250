.LayoutLogotypeComponent {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  color: #000;
  -webkit-user-select: none;
  -webkit-app-region: drag;
  display: flex;
  align-items: center;

  .image {
    display: block;
    width: 100%;
    opacity: 1;
  }
}
