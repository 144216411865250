.LayoutChartComponent {
  flex: 1;
  padding: 20px 20px;

  .title {
    font-weight: bold;
    font-size: 20px;
    padding: 0 0 0px 0;
  }

  .subtitle {
    font-size: 15px;
    font-weight: 100;
    padding: 0 0 20px 0;
  }

  .chart {}
}