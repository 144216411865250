.LayoutVersionComponent {
  color: #000;
  font-size: 16px;
  opacity: 0.6;

  .title {
    display: flex;
    gap: 5px;

    .name {
      font-weight: bold;
    }

    .legal {
      opacity: 0.5;
    }
  }

  .version {
    display: flex;
    opacity: 0.5;
    gap: 5px;
    font-size: 13px;

    .prefix {
      opacity: 0.5;
    }

    .number {
      font-weight: bold;
    }
  }
}