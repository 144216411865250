.DishLogComponent {
  background: rgba(0, 0, 0, 0.352);
  max-width: 400px;
  min-width: 400px;
  position: relative;
  display: flex;
  opacity: 1;
  flex: 1;
  overflow: hidden;
  flex-direction: column;

  .closer {
    font-size: 34px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    color: #fff;
  }

  .title {
    font-size: 23px;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.4);
    padding: 40px 40px 0;

    b {
      color: #fff;
      font-weight: 500;
    }
  }

  ul {
    padding: 25px;
    margin: 35px 0 0 0;
    // linear background red to black vertical
    background: linear-gradient(180deg, #111 0%, #222 50%);
    flex: 1;
    overflow-y: scroll;

    li {
      display: flex;
      background: rgba(48, 48, 48, 0.2);
      border-radius: 3px;
      margin-bottom: 5px;
      color: #fff;
      font-size: 14px;
      padding: 5px;
      align-items: center;
      padding-left: 20px;

      &.ok {
        border-left: 3px solid green;
      }

      &.ko {
        border-left: 3px solid red;
      }


      b {
        margin-left: auto;
        background: rgba(255, 255, 255, 0.098);
        border-radius: 4px;
        padding: 10px;
        font-weight: 100;
        font-size: 12px;
      }
    }
  }
}