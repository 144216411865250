.VariableComponent {
  background-color: #222;
  color: #fff;
  border-radius: 4px;
  padding: 3px 5px 3px 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  i {
    font-size: 14px;
    color: rgb(0, 247, 255);
  }
  .name {
    font-weight: 100;
    border-radius: 4px;
    font-size: 8px;
    letter-spacing: 1px;
    margin-left: auto;
  }
}

.VariableComponent {
  position: absolute;
  right: 0;
  bottom: -11px;
  z-index: 1000;
}
