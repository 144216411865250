:root {
  --color-secondary: #eee;
  --shadow-component: rgba(0, 0, 0, 0.05) 0px 1px 223px 0px;
}

body,
html {
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: default;
  background-color: rgb(255, 255, 255);
}

* {
  font-family: 'Open Sans', sans-serif;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #ddd;
}

*::-webkit-scrollbar-thumb {
  background: #666;
}

.Content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Sidebar {
  background: #fff;
  min-width: 400px;
  max-width: 400px;
}

.Version {
  padding: 20px;
}

.dark {
  padding: 40px;
  background: blue;
}

.AnimationSlideRight {
  animation: TransitionSlideRight 1s cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes TransitionSlideRight {
  from {
    transform: translateX(-140%);
  }

  to {
    transform: translateX(0%);
  }
}

.AnimationSlideUp {
  animation: TransitionSlideUp 1s cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes TransitionSlideUp {
  from {
    opacity: 0;
    transform: translateY(30%);
  }

  to {
    transform: translateY(0%);
  }
}

.AnimationSlideLeft {
  animation: TransitionSlideLeft 1s cubic-bezier(0.002, 0.82, 0.165, 1);
}

@keyframes TransitionSlideLeft {
  from {
    transform: translateX(200%);
  }

  to {
    transform: translateX(0%);
  }
}

.AnimationScale {
  animation: TransitionScale 1s cubic-bezier(0.002, 0.82, 0.165, 1);
}

@keyframes TransitionScale {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}