.LayoutArticleComponent {
  display: flex;
  position: relative;
  z-index: 199;

  .content {
    padding: 40px;
    z-index: 100;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    font-size: min(4vw, 25px);
    gap: 10px;

    @media (max-width: 768px) {
      padding: 60px 30px;
    }

    p {
      margin: 0;
      padding: 0;
      display: block;
    }

    .slogan {
      font-size: 25px;
      color: rgb(0, 0, 0);
      line-height: 1.5;
    }

    .title {
      font-size: 30px;
      font-weight: 500;
      line-height: 1.3;

      color: rgb(0, 0, 0);

      @media (max-width: 768px) {
        font-size: 25px;
        font-weight: bold;
      }
    }

    .subtitle {
      font-size: 19px;
      color: rgb(0, 0, 0);
      line-height: 1.5;
      opacity: 0.7;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        font-size: 19px;
      }
    }

    .description {
      font-size: 15px;
      color: rgb(0, 0, 0);
      line-height: 1.5;
      white-space: pre-line;

      @media (max-width: 768px) {
        font-size: 17px;
      }
    }

    .buttons {
      margin-top: 30px;

      button {
        background-color: #fff;
        border: none;
        color: #000;
        padding: 10px 25px;
        text-transform: uppercase;
        border-radius: 25px;
        display: flex;
        gap: 15px;
        align-items: center;

        i {
          font-size: 20px;
        }
      }
    }
  }

  .video {
    object-fit: cover;
    object-position: center;
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}