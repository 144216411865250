.MeetComponent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: red;


    video {
        background: #333;
    }
}