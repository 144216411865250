.LayoutFormErrorComponent {
  scroll-margin: 100px;

  .items {
    padding: 0px 0;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    box-sizing: border-box;

    font-size: 16px;
    gap: 5px;
    background-color: rgba(255, 0, 0, 0);

    .item {
      border-radius: 5px;
      border: 1px solid rgba(255, 82, 82, 0.2);
      padding: 6px 8px;
      color: rgba(255, 0, 0, 0.688);
      font-size: 13px;
      display: flex;
      align-items: center;
      gap: 10px;

      i {
        font-size: 20px;
      }
    }
  }
}