.LayoutScreenComponent {
  // clearfix
  display: flex;
  flex-direction: column;
  flex: 1;


  @media (max-width: 767px) {
    gap: 0px;
  }

  &.LayoutScreenShowComponent {
    position: fixed;
    right: 0%;
    display: block;
    top: 0%;
    padding: 2%;
    max-width: 900px;
    box-sizing: border-box;
    width: 50%;
    bottom: 0;
    z-index: 10000;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #fff;
    box-shadow: 0px 2px 22px 2999px rgba(0, 0, 0, 0.5);

    @media (max-width: 1207px) {
      width: 100%;
      min-width: 100vw;


    }
  }

  // version phone


  &.LayoutScreenModalComponent {
    position: fixed;
    right: 0px;
    display: block;
    top: 0px;
    width: 540px;
    max-width: 100vw;
    bottom: 0;
    z-index: 10000;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #fff;
    box-shadow: 0px 2px 22px 2999px rgba(0, 0, 0, 0.5);
  }

  .Space {
    min-height: 20px;
    min-width: 20px;

    @media (max-width: 767px) {
      min-height: 0px;
      min-width: 0px;
    }
  }

  .Separation {
    min-height: 0px;
    min-width: 0px;

    @media (max-width: 767px) {
      min-height: 20px;
      min-width: 20px;
    }
  }
}

.LayoutScreenModalBackgroundComponent {
  position: absolute;
  top: 0;
  bottom: 0;
  content: '';
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
}

.LayoutScreenModalComponent {
  animation: TransitionSlideLeft 400ms cubic-bezier(0.002, 0.82, 0.165, 1);

  @media (max-width: 768px) {
    animation: none;
  }
}

@keyframes TransitionSlideLeft {
  from {
    transform: translateX(200%);
  }

  to {
    transform: translateX(0%);
  }
}