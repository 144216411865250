.ConstructorOne {
    .ActionInterface {
        margin: 10px;
        background: pink;
        padding: 10px;

        >.name {
            font-size: 20px;
            font-weight: 600;
            padding: 10px 10px 10px 15px;

            background: rgba(0, 0, 0, 0.3);
        }

        .variable {
            display: flex;
            padding: 6px 6px 6px 15px;
            border-bottom: 1px solid #ddd;
            align-items: center;

            .validation {
                margin-left: auto;
                background: rgba(0, 0, 0, 0.388);
                color: #fff;
                padding: 5px 10px;
                font-size: 12px;
                border-radius: 5px;
            }
        }
    }
}