html,
body {
  overscroll-behavior-x: none;
}

.LayoutCalendarComponent {
  display: flex;
  flex: 1;
  border-radius: 5px;
  flex-direction: column;
  position: relative;
  z-index: 10;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  padding-bottom: 20px;

  .centerSystem {
    border: 1px solid rgb(227, 227, 227);
    display: flex;
    flex: 1;
    margin: 0 auto;
    border-radius: 5px;
    flex-direction: column;
    position: relative;
    z-index: 10;
    height: 100%;
    max-height: 100%;
    overflow: hidden;


  }

}