.LayoutSplashComponent {
  display: flex;
  flex: 1;
  position: relative;
  background: #aaa;
  overflow: hidden;

  * {
    outline: none;
  }

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.516);
    z-index: 10;
  }

  .slogan {
    padding: 10%;
    z-index: 100;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    font-size: 25px;
    gap: 10px;
    white-space: pre-line;

    @media (max-width: 768px) {
      padding: 60px 30px;
    }

    .subtitle {
      font-size: 25px;
      color: rgb(255, 255, 255);
      line-height: 1.5;
      opacity: 0.8;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    .title {
      font-size: 35px;
      font-weight: bold;
      line-height: 1.3;

      color: #fff;

      @media (max-width: 768px) {
        font-size: 25px;
      }
    }

    .description {
      font-size: 22px;
      color: #fff;
      line-height: 1.4;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    .buttons {
      margin-top: 30px;

      @media (max-width: 768px) {
        margin-top: 0px;
      }

      button {
        background-color: #fff;
        border: none;
        color: #000;
        padding: 10px 25px;
        text-transform: uppercase;
        border-radius: 5px;
        display: flex;
        gap: 15px;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: #000;
          color: #fff;
        }

        i {
          font-size: 20px;
        }
      }
    }
  }

  .video {
    object-fit: cover;
    object-position: center;
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    opacity: 0;
    min-width: 100%;
    min-height: 100%;
    transition: all 1s ease-in-out;

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    &.ready {
      opacity: 1;
    }
  }
}