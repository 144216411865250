.DishComponent {
  .layout-class {
    justify-content: inherit !important;
    align-items: inherit !important;

    .canvas {
      display: flex;
      align-items: inherit;
      flex-direction: row;
      flex: 1;

      .cameras {
        align-items: inherit !important;

        .DishVideoComponent {
          flex: 1;
          height: auto !important;
          width: auto !important;
        }
      }

    }


  }

  .layout-focus {
    justify-content: inherit !important;
    align-items: inherit !important;

    .canvas {
      display: flex;
      align-items: inherit;
      flex-direction: row;
      flex: 1;

      .cameras {
        flex-direction: column;
        position: relative;
        align-items: flex-end !important;
        justify-content: flex-start !important;
        margin: 0px;

        .DishVideoComponent {

          height: 150px !important;
          width: 200px !important;

          &.unselected {
            .SelfieDishComponent {
              transform: scale(0.8);
              top: 5px;
              left: 0;
            }

            .remove {
              display: none;
            }

            .controls {
              display: none;
            }

            .selection {
              display: block !important;
            }
          }

          &.selected {
            order: 0;
            border: 0px solid red;
            position: absolute;
            height: inherit !important;
            top: 0px;
            width: inherit !important;
            bottom: 0px;
            right: 210px;
            left: 0px;
          }
        }
      }

    }


  }
}