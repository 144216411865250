.accordionComponent {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;

    animation: AccordionComponentShow 1s cubic-bezier(0.19, 1, 0.22, 1);


    @keyframes AccordionComponentShow {
        from {
            opacity: 0;
            transform: translateY(30%);
        }

        to {
            transform: translateY(0%);
        }
    }

    .accordion__header {
        background: rgb(72, 72, 72);
        color: #fff;
        padding: 10px 15px 10px 23px;
        display: flex;
        align-items: center;
        cursor: pointer !important;

        &:hover {
            background: rgb(50, 50, 50);

        }

        span {
            margin-left: auto;
            display: flex;

            i {
                margin-left: 15px;
                font-size: 20px;
                cursor: pointer !important;
                height: 40px;
                width: 40px;
                text-align: center;
                line-height: 40px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #eee;
                    border-radius: 100px;
                    color: #000;
                    box-shadow: 0px 0px 0px 30px rgba(238, 238, 238, 0.2);
                }
            }
        }
    }

    .accordion__body {
        padding: 15px;
        display: flex;
        gap: 10px;
        flex-direction: column;
    }
}