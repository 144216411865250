.TotalTicketComponent {
    .tax {
        padding: 1rem;
        color: #aaa;
    }

    .total {
        font-size: 1rem;
        padding: 0 2rem;
        text-transform: uppercase;
        font-weight: 100;
        text-align: right;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        b {
            font-size: 2.3rem;
            margin-left: 20px;
            font-weight: bold;
        }
    }

    .footer {
        text-align: justify;
        font-size: 0.9rem;
        color: #aaa;
        margin-top: 1rem;
        padding: 0 1rem 2rem;

    }
}