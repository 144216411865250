.LayoutFormEditorComponent {
  margin-bottom: 20px;

  h1 {
    font-size: 24px;
    margin: 5px 0;
  }

  h2 {
    font-size: 20px;
    margin: 5px 0;

  }

  .public-DraftStyleDefault-block {
    margin: 5px 0
  }

  .rdw-editor-main {
    overflow: visible;
  }

  .public-DraftEditor-content {
    padding: 10px 15px;
    background: transparent;
    border-radius: 5px;
    border: none;
    font-size: 15px;
    border: 1px solid #ccc;
    resize: vertical;
    display: block;
    width: 100%;
    box-sizing: border-box;
    min-height: 200px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    ol {
      margin-top: 5px;
      padding: 30px 20px;
      list-style-position: inside;
    }

    ol li {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 4px;
      list-style-position: inside;
      margin-bottom: 10px;
    }

    // quotes
    .public-DraftStyleDefault-blockquote {
      border-left: 5px solid #ccc;
      padding-left: 10px;
      margin: 10px 0;
    }

    &:hover {
      border-color: #000;
      box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1) !important;
    }
  }
}