.DayMonthCalendar {
    box-sizing: border-box;
    position: relative;
    padding: 0px;
    padding-top: 67px;
    transition: box-shadow 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
    display: inline-block;
    border-left: 1px solid rgba(0, 0, 0, 0.14);
    border-top: 1px solid rgba(0, 0, 0, 0.14);
    font-size: 20px;
    vertical-align: top;
    background-color: #ffffff;
    opacity: 0.8;
    background-size: 10px 10px;

    &.disabled {
        background-color: #eee;
        background-image: repeating-linear-gradient(45deg, #d1d1d1 0, #c6c6c6 1px, #f1f1f1 0, #f1f1f1 50%);
    }



    &.today {
        z-index: 1000;

        .number {
            background: rgb(55, 55, 216) !important;
            color: #fff;
        }


    }


    &:nth-child(1),
    &:nth-child(8),
    &:nth-child(15),
    &:nth-child(22),
    &:nth-child(29),
    &:nth-child(36) {
        border-left: none;
    }

    // first 7 elements border top none
    &:nth-child(-n+7) {
        border-top: none;
    }

    .add,
    .number {
        height: 30px;
        width: 30px;
        font-size: 13px;
        background: #eee;
        line-height: 30px;
        text-align: center;
        border-radius: 100px;
        position: absolute;
        left: 50%;
        margin-left: -15px;
        top: 20px;
        overflow: hidden;
        cursor: pointer;

        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
            transform: scale(1.3);
            background: #000;
            color: #fff;
        }

        // phone
        @media (max-width: 700px) {
            height: 20px;
            width: 20px;
            line-height: 20px;
            font-size: 10px;
            background-color: #fff;
            top: 0;
            left: 0;
            margin-left: 0;
        }
    }

    .performance {
        position: absolute;
        height: 6px;
        width: 40px;
        border-radius: 10px;
        background: rgb(255, 255, 255);
        top: 32px;
        right: 20px;
        display: flex;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);

        // phone
        @media (max-width: 700px) {
            display: none;
        }

        .line {
            border-radius: 10px;
            opacity: 0.5;
            filter: brightness(0.5) saturate(2);
        }
    }

    &.empty {
        background: #ddd;
    }

    &.active:hover {
        cursor: pointer;
        background: rgb(255, 255, 255);
        outline: 4px solid rgb(255, 255, 255);
        box-shadow: 0px 6px 20px 5px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        border-radius: 5px;


    }

    .add {
        position: absolute;
        right: 20px;
        opacity: 1;
        background-color: #fff;
        left: auto;

        &:hover {
            background: #000;
            color: #fff;
            font-size: 18px;

        }
    }

    .events {
        position: absolute;
        left: 0;
        top: 60px;
        right: 0;
        bottom: 0;

        @media (max-width: 700px) {
            top: 20px;
        }

        overflow-y: hidden;
        flex-direction: column;
        flex-wrap: nowrap;

        &:hover {
            overflow-y: scroll;
        }

        .event {
            display: flex;
            position: relative;
            overflow: hidden;
            margin-bottom: 2px;
            cursor: pointer;

            &:hover {
                background-color: #eee;
            }

            .details {
                padding: 1px 0px;
                flex: 1;
                overflow: hidden;

                // phone
                @media (max-width: 700px) {
                    padding: 0;
                }

                .title {
                    font-size: 14px;
                    font-weight: normal;
                    display: flex;
                    align-items: center;
                    gap: 0px;

                    .hour {
                        padding: 3px 6px;
                        border-radius: 3px 3px;
                        font-size: 10px;
                        margin-right: 10px;
                        margin-left: 3px;
                        width: 30px;
                        text-align: center;
                        font-weight: bold;
                        color: #000;
                        background: rgb(246, 246, 246);
                        border: 1px solid rgba(0, 0, 0, 0.1);

                        // phone
                        @media (max-width: 700px) {
                            display: none;
                        }
                    }


                    .subject {
                        font-size: 12px;
                        font-weight: normal;
                        width: 140px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        @media (max-width: 700px) {
                            font-size: 8px;
                            padding: 1px 4px;
                            border-radius: 2px;
                            border: 1px solid blue;
                        }
                    }

                    .type {
                        padding: 3px 6px;
                        border-radius: 3px 3px;
                        font-size: 10px;
                        font-weight: bold;
                        color: #0000009d;
                        background: #fffef3;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        margin-right: 3px;
                        white-space: nowrap;
                        width: 80px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: rgb(42, 39, 39);

                        // phone
                        @media (max-width: 700px) {
                            display: none;
                        }
                    }
                }

                .description {
                    font-size: 12px;
                    display: none;
                    line-height: 1.2;
                }
            }
        }
    }

    &.isToday {
        border: 1px solid #eee;
    }

    &.isSelected {
        background-color: #eee;
    }
}