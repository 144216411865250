.LayoutBoxComponent {
  gap: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;

  // phone
  @media (max-width: 700px) {
    padding: 20px;
  }
}