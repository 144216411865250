.LoaderComponent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
  height: 100px;

  &.invert {
    filter: invert(100%);
  }

  &.desactive {
    display: none;
  }

  svg {
    width: 50px;
  }
}