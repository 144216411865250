#printWindow {
    display: none;
}

.TicketComponent {
    width: 100%;
    flex: 1;
    margin-top: 20px;

    .printer {
        background-color: #000;
        color: #fff;
        border-radius: 5px;
        padding: 20px;
        margin: 20px;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        letter-spacing: 2px;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0px;

        &:hover {
            letter-spacing: 6px;
            box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.4);
        }

    }

    .print {
        width: 500px;
        box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
        margin: 0 auto;
        padding: 0;

        // print cs
        @media print {
            font-size: 5px;
        }
    }
}