.LayoutCalendarMonthComponent {
    background: rgb(255, 255, 255);
    overflow: hidden;
    display: flex;
    border-top: none;

    @media (max-width: 700px) {
        border: none;
        border-radius: 0;
    }

    .frame {
        display: flex;
        gap: 40px;
        width: 100%;

        .bar {
            flex: 1;
            min-width: 400px;
            max-width: 400px;
            background: rgb(52, 52, 52);
            border: 1px solid #eee;
            padding: 20px;
            box-sizing: border-box;
            color: #fff;
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .button {
            cursor: pointer;
        }

        .body {
            width: 100%;

            background: rgb(255, 255, 255);
            display: block;
            font-size: 0;

        }

        .names {
            width: 100%;
            display: flex;
            font-weight: bold;
            flex-wrap: wrap;
            background-color: rgb(44, 44, 44);
            color: #fff;
            text-transform: uppercase;
        }

        .name {
            width: calc(100% / 7);
            text-align: center;
            font-size: 13px;
            font-weight: 200;
            height: 50px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #ddd;

            @media (max-width: 700px) {
                font-size: 10px;
            }

            &:first-child {
                border-left: 0px;
            }

            strong {
                font-weight: bold;
                letter-spacing: 3px;
            }
        }
    }
}