.CalendarLine {
  position: absolute;
  top: 0;
  right: 0px;
  left: 45px;
  z-index: 1001231230000;
  scroll-margin: 100px;
  margin-top: 50px;

  .cursor {
    position: absolute;
    bottom: -7px;
    width: 14px;
    left: 0;
    height: 14px;
    border-radius: 100px;
    cursor: default;
    background: #ea4335;
    z-index: 100000;

    .hour {
      background: #ea4335;
      position: absolute;
      right: 0px;
      top: -5px;
      bottom: -5px;
      border-radius: 25px;
      color: #fff;
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      display: flex;
      transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

      transform: scale(0);
      transform-origin: right center;
    }

    &:hover {
      .hour {
        transform: scale(1);
        display: flex;
      }
    }
  }

  .bar {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    pointer-events: none;
    height: 2px;
    background: #ea4335;
  }
}