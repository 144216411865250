.joystickx {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  border-radius: 210px;
  margin-left: 10px;
  background: rgba(0, 0, 0, 0.4);
}

.knob {
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  transition: 0.04s ease all;
  box-sizing: border-box;
  cursor: grab !important;
  border: 2px solid #fff !important;
  background: none !important;
}

.joystick-touchable {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.joystick-touchable.active {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  overflow: hidden;
}