.LayoutWidgetComponent {
  border-radius: 5px 5px;
  padding: 0px;
  box-shadow: var(--shadow-component);
  border: 1px solid rgb(227, 227, 227);

  background: #fff;

  @media (max-width: 768px) {
    border-radius: 0;
    border-bottom: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
  }
}