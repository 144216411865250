.FormDishComponent {
  padding: 0px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;

  .textarea {
    margin: 0;
    height: 200px;
    resize: none;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    outline: none;
    padding: 30px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.617);
    font-size: 17px;
    line-height: 25px;

    // placeholder color
    &::placeholder {
      color: rgba(93, 93, 93, 0.5);
    }
  }

  .button {
    height: 60px;
    width: 60px;
    font-weight: 100;
    font-size: 25px;
    border-radius: 45px;
    color: rgba(0, 0, 0, 0.6);
    appearance: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    cursor: pointer;
    letter-spacing: 3px;


    &:hover {
      background-color: rgba(255, 255, 255, 1);
      color: #000;
    }
  }
}