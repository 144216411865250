.ConnectedComponent {
    border-top: 1px solid rgba(255, 255, 255, 0.237);
    color: #fff;


    .title {
        padding: 10px 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.237);
        align-items: center;
        position: relative;

        span {
            margin-left: auto;
            background: rgba(255, 255, 255, 0.204);
            color: #fff;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 10px;
            text-transform: uppercase;
            position: absolute;
            right: 10px;
        }

        .circle {
            background-color: #fff;
            height: 10px;
            width: 10px;
            margin-right: 15px;
            position: absolute;
            top: 50%;
            margin-top: -5px;
            left: 15px;
            border-radius: 220px;

            animation: pulseFalse 2s infinite;

            &.true {
                animation: pulseTrue 2s infinite;
            }
        }

        @keyframes pulseTrue {
            0% {
                box-shadow: 0 0 0 0px rgba(30, 255, 0, 0.6);
                background-color: rgb(34, 255, 0);
            }

            100% {
                box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
                background-color: rgb(17, 255, 0);
            }
        }

        @keyframes pulseFalse {
            0% {
                box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.6);
                background-color: rgb(255, 0, 0);
            }

            100% {
                box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
                background-color: rgb(255, 0, 0);
            }
        }
    }

    .user {
        padding: 10px 0px;
        display: flex;
        align-items: center;
        background-color: #eee;
        color: #000;
        font-weight: bold;

        i {
            width: 30px;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.1);
            margin-left: 10px;
            border-radius: 100px;
            height: 30px;
            color: #000;
            font-size: 20px;
            line-height: 30px;
            margin-right: 10px;
        }
    }
}