.PosCategoriesComponent {
  padding: 10px;
  font-size: 0;

  .category {
    height: 140px;
    width: 25%;
    box-sizing: border-box;
    background: #fff;
    display: inline-block;
    color: #000;
    border-radius: 5px;
    padding: 20px;
    font-size: 15px;
    margin: 5px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 2p 25px rgba(0, 0, 0, 0.3);

    .title {
      font-size: 22px;
      font-weight: 500;
    }

    .count {
      font-weight: 100;
      font-size: 15px;
      opacity: 0.7;
    }

    .background {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.1;
    }
  }
}