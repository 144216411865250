.LayoutFormInputComponent {
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    line-height: 30px;
    margin-top: -15px;
    left: 13px;
    opacity: 0.4;
    font-size: 20px;
  }

  input {
    text-indent: 40px;
    border: 1px solid #ccc;
    display: block;
    flex: 1;
    height: 45px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    background-color: transparent;

    &:focus {
      border-color: #000;
      outline: 5px solid rgba(0, 0, 0, 0.1);
    }
  }

  .withoutIcon {
    input {
      text-indent: 15px;
    }
  }
}