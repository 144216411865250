.CalendarDayTimeline {
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    position: sticky;
    font-size: 0px;
    display: inline-block;
    left: 0;
    box-shadow: 0px 0px 71px rgba(0, 0, 0, 0.1);
    border-right: 1px solid #ddd;
    z-index: 10000;
    vertical-align: top;
    box-sizing: border-box;

    >.hour {
        height: 100px;
        min-height: 100px;
        max-height: 100px;
        width: 60px;
        box-sizing: border-box;
        background-color: #fff;
        color: #000;
        width: 100%;
        border-bottom: 1px solid #555;

        &:first-child {
            .box {
                background: none;
            }

            .number {
                display: none;
            }
        }

        &:last-child {
            border-bottom: 1px solid #ddd;

        }

        .box {
            height: 21px;
            color: rgb(0, 0, 0);
            background-color: #fff;
            width: 40px;
            text-align: right;
            padding-right: 10px;
            line-height: 20px;
            transform: translateY(-10px);
            font-size: 10px;

            >.number {
                font-weight: bold;
                display: inline;
            }

            >.sector {
                font-weight: 100;
                display: inline;
                opacity: 0.5;
                text-transform: uppercase;
            }
        }
    }

    &:last-child {
        .box {
            margin-left: 10px;
            float: right;
        }
    }
}