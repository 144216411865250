.PosStatusComponent {
  padding: 10px;
  font-size: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 350px;
  background: #eee;

  .category {
    height: 150px;
    width: 150px;
    background: #333;
    display: inline-block;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    font-size: 15px;
    margin: 10px;
    position: relative;
    overflow: hidden;

    .title {
      font-size: 30px;
    }

    .count {
      font-weight: 100;
    }

    .background {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.2;
    }
  }
}