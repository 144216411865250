.LayoutFormSelectComponent {
  width: 100%;
  position: relative;

  .SearchComponent {
    overflow: hidden;
    border-bottom: 1px solid #ccc;
  }

  .disabled {
    position: absolute;
    top: 50%;
    line-height: 30px;
    margin-top: -15px;
    right: 13px;
    opacity: 0.4;
    font-size: 17px;
    color: rgb(46, 82, 150);
    font-weight: bold;
  }

  .empty {
    min-height: 100px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-items: center;
    flex: 1;
    min-width: 100%;

    b {
      flex: 1;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      letter-spacing: 2px;
      font-weight: 100;
    }
  }

  .input {
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
  }

  .title {
    position: relative;
  }

  .icon {
    display: block;
    width: 20px;
    height: 20px;
    display: flex;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-left: 7px;
    line-height: 43px;

    opacity: 0.4;
    font-size: 20px;
  }

  .window {
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px 5px 0 0;
  }

  .input {
    .selecteds {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .items {
      padding: 0px 0;
      background-color: var(--input-color);
      border: 1px solid var(--input-border);
      display: block;
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      font-size: 16px;
      overflow: hidden;

      &.opening {
        outline: 1px solid var(--input-outline);
      }


      .item {
        border-bottom: 1px solid rgb(222, 222, 222);
        cursor: pointer;
        padding: 0px 5px;
        line-height: 43px;

        display: flex;
        gap: 15px;
        align-items: center;
        font-size: 14px;

        &.disabling {
          border-bottom: 1px solid rgb(222, 222, 222) !important;
          background: transparent !important;
          cursor: default !important;
        }

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.037);
        }

        span {
          display: block;
          width: 20px;
          height: 20px;
          display: flex;
          font-size: 10px;
          align-items: center;
          justify-content: center;
          background: rgba(221, 221, 221, 0.5);
          border-radius: 4px;
          margin-left: 7px;

          // si tiene un icono antes
          &:nth-child(2) {
            display: none;
          }
        }

        .placeholder {
          margin-left: 0px;
        }

        .selecteds {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-right: 10px;
          justify-content: center;
          max-width: 100%;

          .selected {
            padding-left: 0px;

            >i {
              margin-left: 10px;
            }
          }
        }

        .selected {
          padding-left: 30px;
          white-space: nowrap;
          display: inline;
          overflow: hidden;
          text-overflow: ellipsis;

          &:first-child {
            font-size: 14px;
            line-height: 40px;
            padding: 0px 12px;
            gap: 15px;
            background: transparent;

            span {
              margin-left: 0px;
            }

            &.selected {
              span {
                background: var(--primary-color);
                color: #fff;
              }
            }
          }
        }


        .angle {
          margin-left: auto;
          margin-right: 5px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .window {
    .items {
      padding: 0px 0;
      background-color: var(--input-color);
      border: 1px solid var(--input-border);
      display: block;
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      font-size: 16px;
      overflow: hidden;

      &.opening {
        outline: 1px solid var(--input-outline);
      }

      .bubble {
        background: rgba(0, 0, 0, 0.1);
        height: 26px;
        color: rgb(0, 0, 0);
        padding: 0;
        line-height: 26px;
        padding: 0 10px;
        font-size: 13px;
        letter-spacing: 1px;
        border-radius: 5px;
        margin-right: 5px;

        p {
          display: inline;
          padding: 0;
          margin: 0;
        }
      }

      .item {
        border-bottom: 1px solid rgb(222, 222, 222);
        cursor: pointer;
        padding: 0px 5px;
        line-height: 43px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        font-size: 14px;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.037);
        }

        span {
          display: block;
          width: 20px;
          height: 20px;
          display: flex;
          margin-right: 15px;
          font-size: 10px;
          align-items: center;
          justify-content: center;
          background: rgba(221, 221, 221, 0.5);
          border-radius: 4px;
          margin-left: 7px;
        }

        .text {
          margin-right: auto;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .placeholder {
          margin-left: 30px;
        }

        .selecteds {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-right: 10px;

          .selected {
            padding-left: 0px;

            >i {
              margin-left: 10px;
            }
          }
        }

        .selected {
          padding-left: 30px;

          &:first-child {
            font-size: 14px;
            line-height: 40px;
            padding: 0px 12px;
            gap: 15px;
            background: transparent;

            span {
              margin-left: 0px;
            }

            &.selected {
              span {
                background: var(--primary-color);
                color: #fff;
              }
            }
          }
        }


        .angle {
          margin-left: auto;
          margin-right: 5px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .pagination {
    height: 50px;
    padding-left: 20px;
    border-radius: 0 0 5px 5px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background: #eee;
    border-top: none;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .controls {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .button {
      cursor: pointer;
      border: none;
      line-height: 50px;
      height: 50px;
      color: #000;
      align-items: center;
      padding: 0px 25px;
      text-transform: uppercase;
      display: flex;
      gap: 15px;

      &:hover {
        background: #eee;
      }
    }
  }
}