.ContactComponent {
    color: #000;
    background-color: #eee;

    .title {
        padding: 0px 0 0 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.237);
        display: flex;
        align-items: center;

        i {
            margin-left: auto;
            background: rgb(26, 133, 41);
            color: #fff;
            font-size: 10px;
            height: 40px;
            width: 40px;
            text-align: center;
            text-transform: uppercase;
            font-size: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .circle {
            background-color: #fff;
            height: 10px;
            width: 10px;
            margin-right: 15px;
            border-radius: 20px;
        }
    }

}