.LayoutFormInputComponent {
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    line-height: 30px;
    margin-top: -15px;
    left: 13px;
    opacity: 0.4;
    font-size: 20px;
  }

  .finder {
    display: none;
  }

  .image {
    width: 100%;

    >i {
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 15px;
      background: #fff;
      position: absolute;
      right: 7px;
      top: 7px;
      cursor: pointer;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 30px;
    }

    .avatar {
      width: 150px;
      height: 150px;
      background-size: cover;
      border-radius: 150px;
      position: relative;
      background-position: center;
      box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.4);

      i {
        height: 30px;
        width: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 15px;
        background: #fff;
        position: absolute;
        right: 7px;
        top: 7px;
        cursor: pointer;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
        border-radius: 30px;
      }
    }

    img {
      width: 100%;
      border-radius: 6px;
      display: block;
    }
  }

  .input {
    cursor: pointer;
    border: 1px solid #ccc;
    display: block;
    flex: 1;
    height: 45px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    background-color: transparent;
    position: relative;
    padding-left: 45px;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      border-color: #000;
      outline: 5px solid rgba(0, 0, 0, 0.1);
    }
  }
}