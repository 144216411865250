.ControlsComponent {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);
    justify-content: center;
    height: 100px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;

    .line {
        height: 1px;
        width: 1px;
        margin: 10px 0;

    }

    .logo+.line {
        margin-top: 0;
    }

    .logo {
        height: 70px;
        width: 70px;
        cursor: pointer;
        margin: 10px;
        padding: 4px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        display: none;

        &:hover {
            background: rgba(0, 0, 0, 0.174);
        }

        img {
            width: 100%;
        }

        .circle {
            height: 12px;
            width: 12px;
            border-radius: 20px;
            background-color: #fff;
            position: absolute;
            top: 10px;
            right: 10px;
            background: #ff4d4f;
            box-shadow: 0 0 0 1px #fff;

            &.true {
                background-color: #1a8529;
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;


        background: none;
        align-items: center;
        width: 100%;
        flex: 1;
        background-color: rgba(0, 0, 0, 0);
        padding: 15px;

        .space {
            flex: 1;
        }

        .button {
            margin: 5px 10px;

            height: 55px;
            width: 55px;
            font-size: 28px;
            border-radius: 210px;
            color: rgba(255, 255, 255, 0.688);
            appearance: none;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:first-child {
                margin-right: auto;
            }

            &:last-child {
                margin-left: auto;
            }

            sup {
                z-index: auto;
                min-width: 20px;
                height: 20px;
                padding: 0 6px;
                color: #000;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                white-space: nowrap;
                text-align: center;
                background: #fff;
                border-radius: 10px;
                box-shadow: 0 0 0 1px #fff;
                box-sizing: border-box;
                position: absolute;
                top: 10px;
                right: 7px;
                transform: translate(50%, -50%);
                transform-origin: 100% 0%;

                box &.red {
                    background: #ff4d4f;
                    color: #fff;
                }
            }

            &:hover {
                background-color: rgba(255, 255, 255, 1);
                color: #000;
                box-shadow: 0px 0px 0px 50px rgba(255, 255, 255, 0.07);

                .la-cog {
                    // rotate infinite
                    animation: spinEr 2s linear infinite;
                }

                sup {
                    background-color: black;
                    box-shadow: #000 0 0 0 1px;
                    color: #fff;
                }

                @keyframes spinEr {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }

        .exit {
            color: #ff4d4f;

            i {
                margin-top: 5px;
                transform: rotate(135deg);
            }
        }
    }
}