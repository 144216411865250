.ConstructorOne {
    .ComponentManager {
        background: rgba(205, 255, 205, 0.685);
        border-radius: 5px;
        overflow: hidden;
        min-width: 200px;
        display: flex;
        flex-direction: column;

        >.type {
            font-size: 13px;
            font-weight: 600;
            padding: 10px 10px 10px 15px;
            color: #fff;
            background: rgba(0, 0, 0, 0.663);
            display: flex;
            align-items: center;

            i {
                margin-left: auto;
                font-size: 20px;
            }
        }

        >.name {
            font-size: 13px;
            font-weight: 600;
            padding: 10px 10px 10px 15px;

            background: rgba(0, 0, 0, 0.3);
        }

        .column {
            display: flex;
            padding: 6px 6px 6px 15px;
            border-bottom: 1px solid #ddd;
            align-items: center;

            .type {
                margin-left: auto;
                background: rgba(0, 0, 0, 0.388);
                color: #fff;
                padding: 5px 10px;
                font-size: 12px;
                border-radius: 5px;
            }
        }

        .components {
            padding: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            gap: 10px;
            flex-direction: column;

            .components {
                background-color: #000;

                .components {
                    background-color: #666;
                }
            }
        }
    }
}