.DishVideoComponent {
    height: 240px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 300px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

    button {
        font-size: 22px;
        height: 50px;
        width: 50px;
        border-radius: 220px;
        background: rgba(0, 0, 0, 0.4);
        border: none;
        margin-left: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            background: rgba(0, 0, 0, 0.6);
        }
    }

    .render {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 100;
        ;
    }

    .remove {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 1000;
        ;
    }

    .settings {
        background: rgba(255, 255, 255);
        position: absolute;
        z-index: 10200;
        bottom: 30px;
        overflow: hidden;
        top: 30px;
        box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);

        width: 400px;
        border-radius: 10px;
        color: #000;
        right: 30px;


        .infor {
            font-size: 14px;
            padding: 0 20px 0px;
            margin-left: 30px;
            position: relative;
            padding-left: 35px;

            margin-top: 0;

            i {
                font-size: 30px;
                position: absolute;
                left: -10px;
                top: 12px;
            }
        }
    }


    .selection {
        display: none;
    }

    .selection {
        position: absolute;
        bottom: 10px;
        transform: scale(0.8);
        right: 10px;
        z-index: 1000;
        ;
    }

    .name {
        background: rgba(0, 0, 0, 0.145);
        padding: 8px 13px;
        position: absolute;
        color: #fff;
        font-size: 12px;
        left: 0;
        border-top-right-radius: 5px;
        bottom: 0;
    }

    .controls {
        position: absolute;
        z-index: 1000;
        bottom: 20px;
        left: 0;
        right: 0;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.loading {
            opacity: 0;
        }

        .selfie {
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: auto;
            position: absolute;
            z-index: 1000;

            b {
                height: 50px;
                width: 50px;
                background-color: #fff;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            span {
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                color: #fff;

                em {
                    color: #fff;
                    background: #000;
                    padding: 2px 8px;
                    font-size: 13px;
                    align-self: flex-start;
                    border-radius: 100px;
                    border-radius: 100px;
                    font-style: normal;
                }
            }


        }


    }



    canvas {
        position: absolute;
        left: -20px;
        top: 0;
        bottom: 0;
        right: -20px;
        width: 110%;
        height: 100%;
    }

    .LoaderComponent {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex: 1;
        height: 100%;
        z-index: 10000;
    }

    .render {
        &.loading {
            opacity: 0;
        }
    }

    video {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
        transform: scaleX(-1);
        filter: hue-rotate(-6deg) brightness(0.9) saturate(0.97);

        &.contain {
            object-fit: contain;
        }

        &.invert {
            filter: invert(1);
        }

        &.coverSystemContain {
            object-fit: contain;
            position: relative;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .flip video {
        transform: scaleX(1);
    }
}