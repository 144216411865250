.LayoutBarComponent {
  font-size: 12px;
  display: flex;
  padding: 50px 40px 0;
  align-items: center;
  gap: 20px;
  color: #000;

  @media (max-width: 768px) {
    padding: 5% 5% 0;
  }

  .icon {
    font-size: min(6vw, 30px);
  }

  .close {
    margin-left: auto;
    font-size: min(6vw, 40px);
    opacity: 0.22;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .text {
    gap: 2px;

    .title {
      font-size: min(5vw, 20px);
      font-weight: bold;
      padding: 0;
      line-height: 1;
    }

    .description {
      padding: 0;
      font-size: 15px;
      font-weight: 200;
    }
  }
}