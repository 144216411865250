.LayoutFormInputComponent {
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    line-height: 30px;
    margin-top: -15px;
    left: 13px;
    opacity: 0.4;
    font-size: 20px;
  }

  .disabled {
    position: absolute;
    top: 50%;
    line-height: 30px;
    margin-top: -15px;
    right: 13px;
    opacity: 0.4;
    font-size: 17px;
    color: rgb(46, 82, 150);
    font-weight: bold;
  }

  input {
    text-indent: 40px;
    border: 1px solid #ccc;
    display: block;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    flex: 1;
    height: 45px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    background-color: transparent;

    &:focus {
      border-color: #000;
      outline: none;

      // disable outline in phone
      @media screen and (max-width: 600px) {
        outline: none;
      }
    }
  }

  .withoutIcon {
    input {
      text-indent: 15px;
    }
  }
}