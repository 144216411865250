.LayoutFormSubmitComponent {
  margin-bottom: 0px;
  margin-top: 20px;
  display: flex;
  flex: 1;

  @media (max-width: 700px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  button {
    margin-left: auto;
    background-color: rgba(0, 0, 0, 0.05);
    border: none;
    color: #000;
    cursor: pointer;
    padding: 10px 15px;
    text-transform: uppercase;
    border-radius: 5px;
    display: flex;
    gap: 15px;
    align-items: center;

    &:hover {
      background-color: rgb(0, 0, 0);
      outline: none;
      color: #fff;
    }

    i {
      font-size: 20px;
    }

    //phone
    @media (max-width: 700px) {
      padding: 5px 10px;
      font-size: 17px;
      gap: 10px;
      width: 100%;
      background-color: #000;
      padding: 15px;
      color: #fff;
      text-align: center;
      justify-content: center;

      i {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 700px) {

    & {
      flex-direction: column-reverse;
    }

    .buttons .button {
      padding: 5px 10px;
      font-size: 17px;
      gap: 10px;
      width: 100%;
      background-color: rgb(210, 210, 210);
      padding: 15px;
      color: #000;
      margin-top: 10px;
      text-align: center;
      justify-content: center;

    }

  }
}