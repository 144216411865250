.AnswerBackground {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(11px);
    z-index: 10022220;
}

.AnswerComponent {
    position: fixed;
    width: 300px;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -150px;
    height: 300px;
    background: rgba(0, 0, 0, 0.675);
    z-index: 1000000000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 15px;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;

    .title {
        font-size: 25px;
        font-weight: bold;
        padding: 15px 0 0 15px;
        text-align: left;
        color: #fff
    }

    p {
        text-align: left;
        padding: 0 15px;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 100;
        line-height: 1.6;

        b {
            font-weight: bold;
            color: #fff;
        }
    }

    .description {
        font-size: 18px;
        font-weight: bold;
        padding: 15px;
        text-align: left;
        color: #fff
    }

    .buttons {
        display: flex;
        margin-top: auto;
        margin: auto 10px 10px;
        overflow: hidden;
        gap: 10px;

        * {
            flex: 1;
            padding: 15px 0px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }
        }

        .accept {
            background: rgb(60, 183, 30);
            color: #fff
        }

        .cancel {
            background: rgba(255, 255, 255, 0.18);
            color: #fff
        }
    }
}