.LayoutFormTextareaComponent {
  margin-bottom: 20px;

  .textarea {
    padding: 10px 15px;
    background: transparent;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    border: 1px solid #ccc;
    resize: vertical;
    display: block;
    width: 100%;
    box-sizing: border-box;
    min-height: 200px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &:focus {
      border-color: #000;
      outline: none;

    }
  }
}