.LayoutBreadcrumbComponent {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.589);
    display: flex;
    padding: 50px;
    align-items: center;
    background: rgb(255, 255, 255);
    color: #342c57;
    background: red;

    .icon {
        font-size: 70px;
    }

    .text {
        margin-left: 40px;

        .title {
            font-size: 35px;
            font-weight: bold;
        }

        .description {
            font-size: 22px;
            font-weight: 200;

        }
    }

}