.LayoutPosComponentForm {
  font-size: 12px;
  position: relative;
  display: flex;
  flex: 1;
  background-color: rgb(162, 162, 162);
  flex-direction: column;
  border-radius: 5px;
  padding: 20px;

  .header {
    position: sticky;
    top: -5px;
    z-index: 100;
    border-radius: 10px;

    overflow: hidden;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    box-shadow: 0px 1px 31px rgba(0, 0, 0, 0.1);

    &::after {
      position: absolute;
      left: -30px;

      backdrop-filter: blur(5px) brightness(0.5) invert(0);
      bottom: 0;
      right: 0;
      top: -20px;
      content: '';
      display: block;
      z-index: -10;
    }

    .total {
      position: sticky;
      top: 0;
      font-size: 60px;
      color: #fff;
      flex: 1;
      padding: 30px 30px 0;
    }

    .method {
      color: #fff;
      padding: 8px 12px;
      font-size: 14px;
      display: flex;
      align-self: flex-start;
      border-radius: 5px;
      align-items: center;
      gap: 15px;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 30px;
      border: 1px solid rgba(255, 255, 255, 0.286);

      .icon {
        font-size: 22px;
      }

      .angle {
        opacity: 0.5;
        font-size: 14px;
      }
    }
  }

  .head {
    display: flex;
    padding: 30px 30px 0 30px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0.5;

    .price {
      margin-left: auto;
      padding: 0 20px;
    }

    .units {
      min-width: 110px;
      width: 110px;
      text-align: center;
    }
  }

  .items {
    padding: 10px 30px 0;
    color: #fff;
    font-size: 14px;

    .item {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.8);
      margin-bottom: 10px;
      display: flex;
      overflow: hidden;
      justify-content: flex-start;

      .name {
        font-size: 15px;
        padding: 10px 15px;
      }

      .price {
        margin-left: auto;
        font-size: 15px;
        color: rgb(255, 255, 255);
        padding: 10px 15px;
      }

      i {
        background: rgba(255, 255, 255, 0.1);
        display: flex;
        min-width: 30px;
        width: 30px;
        color: rgb(255, 161, 161);
        margin: 5px;
        border-radius: 3px;
        text-align: center;
        max-width: 30px;
        justify-content: center;
        align-items: center;

        &:last-child {
          background: rgba(255, 255, 255, 1);

          color: rgb(27, 41, 133);
        }
      }

      .units {
        background: rgba(80, 80, 80, 0);
        font-size: 15px;
        text-align: center;
        width: 40px;
        justify-content: center;
        align-items: center;
        display: flex;
        gap: 10px;
        color: rgb(255, 255, 255);
        padding: 0px 0px;
      }
    }
  }

  .add {
    padding: 10px 15px;
    margin: 10px 30px 10px;
    margin-left: auto;
    display: flex;
    color: rgb(0, 0, 0);
    z-index: 1;
    align-items: center;
    background: rgba(255, 255, 255, 0.468);
    text-transform: uppercase;
    gap: 10px;
    border-radius: 5px;

    i {
      font-size: 20px;
      opacity: 0.5;
    }
  }

  .submit {
    position: sticky;
    top: 175px;
    color: #fff;
    z-index: 1;
    padding: 5px 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.189);

    .button {
      background: rgb(255, 255, 255);
      padding: 5px 15px;
      color: rgb(101, 101, 101);
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: center;
      border-radius: 5px;
      font-weight: bold;
    }
  }
}