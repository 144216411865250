.CalendarWeek {
    display: flex;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1;
    flex-direction: column;
    overflow: none;
    display: flex;
    flex-direction: column;
}