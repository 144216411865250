.CalendarEventCube {
    position: absolute;
    background: rgb(83, 85, 204);
    border-radius: 5px;
    box-sizing: border-box;
    max-width: 230px;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.156);
    outline: 2px solid #fff;
    font-size: 11px;

    &:hover {
        z-index: 10;
        max-height: 300px !important;
        min-width: 230px !important;
    }

    .controls {
        display: flex;
        margin: 0 10px 10px;

        i {
            height: 40px;
            width: 40px;
            background: rgba(255, 255, 255, 0.14);
            border-radius: 100px;
            font-size: 20px;
            display: flex;
            margin: 5px;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
            transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:hover {
                transform: scale(1.1);
                background: rgba(255, 255, 255, 0.3);
            }
        }
    }

    .subject {
        color: #fff;
        position: relative;
        z-index: 10;
        align-items: center;
        padding: 0px 0 0 14px;
        height: 30px;
        min-height: 30px;
        display: flex;
        padding-right: 80px;

        b {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bubble {
        color: #fff;
        font-size: 12px;
        padding: 5px 10px;
        margin-right: auto;
        z-index: 1;
        font-weight: bold;
        position: relative;
        margin: 5px;
        border-radius: 100px;
        overflow: hidden;

        span {
            font-weight: 100;
            font-size: 10px;
            color: rgba(255, 255, 255, 0.9);
            display: block;

        }
    }

    .description {
        padding: 13px 16px 0;
        color: #333;
        font-size: 11px;
        white-space: normal;
        color: #fff;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        max-height: 45px;
        overflow: hidden;

        p {
            margin: 0;
        }

        strong {
            display: block;
        }
    }

    .clock {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        color: #fff;
        font-weight: bold;
        background: rgb(83, 85, 204);
        font-size: 11px;
        flex: 1;
        width: 80px;
        min-width: 80px;
        position: absolute;
        right: 0;
        top: 0;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


}