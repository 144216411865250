.SettingsDishComponent {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(35px) grayscale(1);

}