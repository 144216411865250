.ObjectComponent {
  background-color: #222;
  color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    padding: 5px;
    border-bottom: 1px solid #666;

    &:last-child {
      border-bottom: none;
    }
  }

  .key {
    padding: 2px 5px;
    border-radius: 4px;
    letter-spacing: 1px;
    font-size: 10px;
    background: rgba(255, 255, 255, 0.135);
  }

  .value {
    padding: 2px 5px;

    border-radius: 4px;
    letter-spacing: 1px;
    font-size: 10px;
  }

  .type {
    font-weight: 100;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 2px 5px;
    font-size: 10px;
    color: rgb(188, 255, 255);

    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: auto;
  }
}