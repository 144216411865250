.FormComponent {
  display: flex;
  flex-direction: column;
  position: relative;

  .FormComponentInputError {
    border-radius: 5px;
    border: 1px solid rgba(255, 82, 82, 0.2);
    padding: 6px 8px;
    color: rgba(255, 0, 0, 0.688);
    font-size: 13px;
    display: flex;

    align-items: center;
    margin-bottom: -5px;
    gap: 5px;

    i {
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .success {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);

    i {
      font-size: 40px;
      margin-right: 20px;
    }
  }

  .loading-box {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background: rgb(255, 255, 255);
    z-index: 100;
    display: flex;
    justify-content: center;
    border-radius: 10px;

    .LoaderComponent {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  .header {
    margin-bottom: 10px;

    .title {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
    }

    .description {
      font-size: 18px;
      font-weight: 100;
      opacity: 0.5;
    }

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  .divisor {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 35px 60px 15px 60px;
    text-align: left;
    margin-left: -60px;
    margin-right: -60px;
    margin-top: 20px;
    letter-spacing: 2px;
    font-size: 22px;
    font-weight: bold;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to bottom,
          rgba(0, 0, 0, 0.05),
          rgba(0, 0, 0, 0));
    }

    .description {
      font-weight: 200;
      font-size: 15px;
    }
  }

  .FormComponentInputHidden {
    display: none;
  }

  .FormComponentInput {
    scroll-margin: 100px;
    margin-top: 20px;

  }

  .line {
    scroll-margin: 100px;

    display: flex;
    gap: 20px;

    .FormComponentInput {
      flex: 1;
    }
  }



  .multiple {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;

    .more {
      margin-left: auto;
      display: flex;
      padding: 20px;

      i {
        margin-left: auto;
        height: 50px;
        width: 50px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 210px;
        color: #000;
        font-size: 25px;
        text-align: center;
        line-height: 50px;

        &:hover {
          background: #000;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .multipleItem {}
  }
}