.BackgroundDishComponent {
    .backgrounds {
        padding: 20px 0;
        font-size: 0;
    }

    .background {
        display: inline-block;
        height: 110px;
        width: 110px;
        font-size: 14px;
        vertical-align: top;
        margin-right: 7px;
        margin-bottom: 7px;
        background: #fff;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        position: relative;
        box-shadow: 0px 0px 221px #aaa;

        &:nth-child(3) {
            margin-right: 0;
        }

        &:hover {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 10px;
                border: 5px solid rgba(98, 98, 98, 0.1);
                box-sizing: border-box;
            }


        }

        .center {
            font-size: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
        }

        .selected {
            font-size: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            border: 5px solid rgb(149, 160, 255);
            border-radius: 10px;
            color: transparent;
        }

        .remove {
            height: 30px;
            width: 30px;
            position: absolute;
            top: -15px;
            right: -15px;
            background: rgb(218, 94, 94);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 40px;
            z-index: 1000;
        }
    }
}