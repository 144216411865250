.LayoutGalleryComponent {
  font-size: 12px;
  margin: 0px;
  margin-top: 20px !important;

  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    i:first-child {
      opacity: 0.7;
    }

    i {
      font-size: 20px;
      margin-right: 10px;
      background: #000;
      color: #fff;
      cursor: pointer;
      height: 50px;
      width: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  iframe {
    opacity: 0;
    border: none;
  }

  position: relative;

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
  }

  .item {
    position: relative;
    border-radius: 5px;
    display: inline-block;
    overflow: hidden;
    background: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;

    @media (max-width: 768px) {
      box-shadow: none;
      border-radius: 0;
    }

    .image {
      opacity: 1;
      border-radius: 5px;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

      display: block;
      height: 100%;
      background-size: cover;
      background-position: center;
      width: 100%;

      @media (max-width: 768px) {
        border-radius: 0px;
      }
    }

    &:hover {
      .icon {
        display: block;
      }
    }

    >.icon {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 20px;
      font-size: 30px;
      color: #fff;
    }

    .name {
      font-size: 14px;
      font-weight: bold;
      padding: 10px 10px 0;
    }

    .sizes {
      font-size: 14px;
      font-weight: 300;
      padding: 5px 10px 10px;
    }

    .details {
      border-radius: 5px;

      position: absolute;
      right: 0;
      top: 0;
      padding: 20px;
      font-size: 30px;

      display: block;
      z-index: 100;
    }

    .buttons {
      border-radius: 5px;

      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 50px;
      padding: 20px;
      font-size: 30px;
      gap: 20px;
      display: flex;
      z-index: 100;

      @media (max-width: 768px) {
        height: 30px;
        line-height: 30px;
        padding: 10px;
        gap: 10px;
      }

      .LayoutButtonComponent .button {
        padding: 0;
        height: 40px;
        width: 40px;

        text-align: center;
        display: flex;
        align-content: center;
        line-height: 40px;
        display: block;
        justify-content: center;
        background: rgba(0, 0, 0, 0.456);

        @media (max-width: 768px) {
          height: 30px;
          width: 30px;
          line-height: 30px;
        }

        .icon {
          padding: 0;
          height: 40px;
          width: 40px;
          text-align: center;
          display: flex;
          align-content: center;
          line-height: 40px;
          display: block;
          opacity: 1;
          color: #fff;
          justify-content: center;

          //phone
          @media (max-width: 768px) {
            height: 30px;
            width: 30px;
            line-height: 30px;
            font-size: 16px;
          }
        }
      }
    }


  }

  .modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 5% 5%;
    right: 0;
    box-sizing: border-box;
    text-align: center;
    z-index: 100;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;

    img {
      margin: 0 auto;
      display: block;
      max-width: 100%;
      max-height: 100%;

      height: auto;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
        rgba(0, 0, 0, 0.05) 0px 5px 10px;
    }

    .movable {
      width: 40px;
      height: 40px;
      background: gold;
      position: fixed;
      z-index: 999999999;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      pointer-events: none;
    }
  }
}