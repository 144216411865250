.LayoutTableComponent {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);
  display: block;
  color: #000;
  background: #fff;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  /*width: 100%; this works, but dont want it full until box is equal or smaller than content*/
  .empty {
    min-height: 300px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-items: center;
    flex: 1;
    min-width: 100%;

    b {
      flex: 1;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      letter-spacing: 2px;
      font-weight: 100;
    }
  }

  .search {
    flex: 1;
    position: relative;
    border-bottom: 1px solid #ddd;
    height: 45px;
    overflow: hidden;

    .search-submit {
      font-size: 22px;
      width: 45px;
      opacity: 0.5;
      position: absolute;
      right: 0px;
      top: 21px;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .search-close {
      font-size: 22px;
      width: 45px;
      opacity: 0.5;
      position: absolute;
      right: 50px;
      top: 21px;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .icon {
      font-size: 20px;
      transform: scaleX(-1);
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;

    }

    input {
      padding: 0;
      margin: 0;
      width: 100%;
      box-sizing: border-box;
      outline: none;
      border: none;
      height: 45px;
      line-height: 60px;
      font-size: 16px;
      padding-left: 45px;
    }
  }

  .scroll {
    display: inline-block;
    overflow: scroll;
    overflow-y: hidden;

    max-width: 100vw;
    overflow-x: scroll;
  }

  .head {
    white-space: nowrap;
    display: block;

    font-size: 0;
    display: block;
    width: 100%;
    cursor: default;
    max-width: 100%;
    display: flex;

    @media (max-width: 768px) {
      display: none;
    }

    .space {
      flex: 1;
      border-left: none !important;
    }

    .space,
    .column {
      position: relative;
      border-bottom: 1px solid #ddd;
      display: inline-block;
      align-items: center;
      font-size: 14px;
      color: rgb(101, 101, 101);
      vertical-align: top;
      min-width: 45px;

      &:first-child {
        border-left: none;
      }

      .text {
        line-height: 45px;
        padding: 0 15px;
      }

      .mark {
        line-height: 45px;
        padding: 0 15px;
      }

      .status {
        line-height: 45px;
        padding: 0 15px;
      }

      .price {
        line-height: 45px;
        padding: 0 15px;
      }

      .image {
        width: 45px;
      }

      .boolean,
      .icon {
        opacity: .4;
        width: 45px;
        line-height: 45px;
        padding: 0 15px;
        box-sizing: border-box;
        font-size: 20px;
      }
    }
  }

  .body {
    display: block;
    width: 100%;
    max-width: 100%;

    &:last-child {
      border-bottom: 1px solid #ddd;
    }

    .row {
      flex: 1;
      display: flex;
      white-space: nowrap;
      width: 100%;
      height: 45px;

      &:last-child {
        border-bottom: none;
      }

      &:nth-child(odd) {

        .column,
        .space {
          background: rgba(0, 0, 0, 0.027);
        }
      }

      .space {
        flex: 1;
      }

      .space {
        border-left: none !important;
      }

      .space,
      .column {
        border-bottom: 1px solid #ddd;

        border-left: 1px solid rgba(0, 0, 0, 0.047);
        display: inline-block;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        min-width: 45px;
        height: 45px;

        &:first-child {
          border-left: none;
        }

        .image {
          width: 45px;
          height: 45px;
          flex: 1;
          background-size: 120% auto;
          background-position: center;
        }

        .content {
          padding: 0 15px;
          display: inline-block;
          align-items: center;
          white-space: nowrap;
          width: 100%;
          box-sizing: border-box;

          overflow: hidden;
          height: 45px;
          line-height: 45px;
          text-overflow: ellipsis;
          gap: 4px;

          .content {
            padding: 0;
          }

          .icon {
            margin-right: 10px;
            height: 45px;
            line-height: 45px;
            opacity: 0.5;
            width: auto;
            display: inline-block;
          }

          .text {
            padding: 0;
            display: inline-block;
            margin-right: 5px;
            display: inline;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .mark {
            display: inline-block;
            margin-right: 5px;
          }

          .mark-box {
            margin: 5px 0;
            height: auto;
            padding-right: 10px;
            height: 30px;
            flex: 0;
            box-sizing: border-box;
          }
        }

        .LayoutButtonComponent .button {
          padding: 0;
          color: #000;
          border-radius: 0 !important;

          .icon {
            opacity: 1;
          }
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          flex: 1;
          height: 45px;
          max-width: 45px;

          width: 45px;
          padding: 0;
          margin: 0;
        }

        .text {
          height: 45px;

          line-height: 45px;
          padding: 0 15px;
        }

        .status {
          height: 45px;
          display: flex;
          align-items: center;
        }

        .detail {
          height: 45px;
          display: flex;
          align-items: center;
        }

        .mark-box {

          .mark-color {
            content: '';
            display: block;
            height: 8px;
            width: 8px;
            background-color: red;
            border-radius: 50%;
            margin-right: 10px;
          }


          box-sizing: border-box;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 1px;
          flex: 1;
          align-items: center;
          display: flex;
          padding: 5px 15px;
          background-color: #fff;
          border-radius: 5px;
          margin: 10px;
          border: 1px solid rgba(0, 0, 0, 0.176);
        }

        .status-box {
          &::before {
            content: '';
            display: block;
            height: 8px;
            width: 8px;
            background-color: red;
            border-radius: 50%;
            margin-right: 10px;
          }

          box-sizing: border-box;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 1px;
          flex: 1;
          align-items: center;
          display: flex;
          padding: 5px 15px;
          background-color: #fff;
          border-radius: 5px;
          margin: 10px;
          border: 1px solid rgba(0, 0, 0, 0.176);
        }

        .status-box.statusGreen {
          border: 1px solid rgba(0, 0, 0, 0.176);
          background-color: #e6ffe6;
          color: #4caf50;

          &::before {
            background-color: #4caf50;
          }
        }

        .status-box.statusRed {
          border: 1px solid rgba(0, 0, 0, 0.176);
          background-color: #ffe6e6;
          color: #f44336;

          &::before {
            background-color: #f44336;
          }
        }

        .status-box.statusGrey {
          border: 1px solid rgba(0, 0, 0, 0.176);
          background-color: #e8e8e8;
          color: #444;

          &::before {
            background-color: #444;
          }
        }

        .price {
          padding: 15px;
          text-align: right;
        }
      }
    }
  }

  .pagination {
    height: 45px;
    padding-left: 20px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .controls {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .button {
      cursor: pointer;
      border: none;
      line-height: 45px;
      height: 45px;
      color: #000;
      align-items: center;
      padding: 0px 25px;
      text-transform: uppercase;
      display: flex;
      gap: 15px;

      &:hover {
        background: #eee;
      }
    }
  }
}