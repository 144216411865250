.CalenportComponent {
    .bar {
        background: #eee;
        padding: 14px 21px;
        margin-top: 20px;
        border-radius: 7px;
        position: relative;
        z-index: 1;
        overflow: hidden;

        div {
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            width: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            background: #aaa;
        }
    }

    .events {
        height: 300px;
        overflow: hidden;
        position: relative;
        overflow-y: scroll;
        border-radius: 7px;
        background: #eee;

        .counter {
            position: sticky;
            top: 0;
            background: #000;
            padding: 15px 20px;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.5);
            font-weight: 100;

            b {
                color: #fff;

                font-weight: bold;
            }
        }

        .login {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                div {

                    &:first-child {
                        display: flex;
                        justify-content: center;
                        margin-right: 0 !important;
                        align-items: center;
                    }

                }
            }
        }

        .event {

            padding: 8px 20px;
            border-bottom: 1px solid #ccc;
            font-size: 12px;

            &:nth-child(odd) {
                background: rgba(205, 205, 205, 0.337);
            }
        }
    }

    .submit {
        margin-bottom: 0px;
        margin-top: 20px;
        display: flex;
        flex: 1;

        @media (max-width: 700px) {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        button {
            background-color: rgba(0, 0, 0, 0.7);
            border: none;
            color: #fff;
            cursor: pointer;
            padding: 10px 15px;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            border-radius: 5px;
            justify-content: center;
            display: flex;
            gap: 15px;
            align-items: center;

            &:hover {
                background-color: rgb(0, 0, 0);
                outline: none;
                color: #fff;
            }

            i {
                font-size: 20px;
            }

            //phone
            @media (max-width: 700px) {
                padding: 5px 10px;
                font-size: 17px;
                gap: 10px;
                width: 100%;
                background-color: #000;
                padding: 15px;
                color: #fff;
                text-align: center;
                justify-content: center;

                i {
                    font-size: 22px;
                }
            }
        }
    }
}