.ConstructorOne {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    background: #000;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}